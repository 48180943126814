<link rel="stylesheet" href="../../../../../app.scss">
<template>
  <div class="investment-confirmation">
    <div class="container-xl">
      <div class="card mb-3">
        <div v-if="!confirmation.uuid" class="text-center p-5"><i>Загрузка...</i></div>
        <div v-if="confirmation.uuid" class="card-body">
          <div class="h6">№ {{ confirmation.agreementNumber }}</div>
          <iframe :src="confirmation.documentUrl"></iframe>
          <form @input="validateForm" @change="validateForm" @submit="confirm">
            <div v-if="steps[steps.length - 1].toString() === $route.params.step">
              <div class="row flex-column-reverse flex-lg-row mt-4">
                <div class="col-12 col-lg-8">
                  <div class="form-group">
                    <div class="custom-control custom-checkbox">
                      <input v-model="agreement" type="checkbox" class="custom-control-input" id="agreement" required>
                      <label class="custom-control-label" for="agreement"></label>
                      <public-link to="/page/politika-vzyskaniia-prosrochennoi-zadolzhennosti"
                                   target="_blank"
                      >Политика взыскания просроченной задолженности
                      </public-link>
                    </div>
                    <input-errors :errors="errors.agreement"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm mb-3 mb-md-0">
                <div v-if="confirmation.signRequired">
                  <div class="mb-3">
                    <span class="badge badge-secondary" :class="chosenCertificate && 'status__ok'">1</span>
                  </div>
                  <button @click="getCert" class="btn btn-info" type="button">
                    Выбрать сертификат
                  </button>
                  <div v-if="signPluginError" class="small text-danger">{{signPluginError}}. Воспользуйтесь <a href="https://www.cryptopro.ru/sites/default/files/products/cades/demopage/cades_bes_sample.html">страницей диагностики</a>.</div>
                  <div v-else-if="chosenCertificate" class="small text-success">Сертификат выбран</div>
                  <div class="mt-2">
                      <div class="small">
                          Для настройки компьютера используйте <a href="https://www.cryptopro.ru/sites/default/files/products/cades/demopage/cades_bes_sample.html">страницу диагностики</a>
                      </div>
                    <div class="small">
                          Подробное описание в <a href="https://cdn.lendly.ru/crypto-pro-intall-windows.pdf">инструкции</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm mb-4 mb-md-0">
                <div v-if="confirmation.signRequired">
                  <div class="mb-3">
                    <span class="badge badge-secondary" :class="confirmation.confirmed && 'status__ok'">2</span>
                  </div>
                  <button @click="signDocument" class="btn btn-info" type="button"
                          :class="!chosenCertificate || confirmation.confirmed ? 'btn-secondary' : 'btn-info'"
                          :disabled="!chosenCertificate || confirmation.confirmed"
                  >
                    Подписать документ
                  </button>
                  <div v-if="confirmation.confirmed" class="small text-success">Документ подписан</div>
                  <div v-if="signatureCreateError" class="small text-danger">{{signatureCreateError}}</div>
                </div>
              </div>
              <div class="col-sm col-md-3">
                <button type="submit" class="btn btn-lg btn-success"
                        :disabled="confirmation.signRequired && !confirmation.confirmed"
                >
                  <div v-if="$route.params.step === '1' || $route.params.step === '3'">Акцепт Оферты</div>
                  <div v-else>Подтвердить</div>
                </button>
                <div v-if="$route.params.step === '3'" class="small text-muted">Акцепт оферты на заключение<br/>договора управления залогом</div>
              </div>
            </div>
            <div class="mt-4">
              Подписание настоящего агентского поручения осуществляется Принципалом и фиксируется в электронной форме с
              использованием информационных
              технологий и технических средств Площадки.
            </div>
          </form>
        </div>
      </div>
    </div>
    <processing :show="processing"/>
  </div>
  <dialog-modal class="dialog-choose-cert overflow-auto" ref="dialog">
    <div v-if="!certsLoaded" class="text-center">
      <div class="spinner-border" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="certificates-list">
      <div v-for="cert in this.certificates">
        <div class="card cert-card ml-2 mt-2" @click="setCert(cert)"
             :class="chosenCertificate && chosenCertificate.thumbprint === cert.thumbprint && 'cert-card__chosen'">
          <div class="card-body">
            <div class="card-title">{{ cert.name }}</div>
            <div class="d-block">Действителен до: {{ cert.validTo }}</div>
            <div class="text-muted small">Выдан: {{ cert.issuerName }}</div>
          </div>
        </div>
      </div>
    </div>
  </dialog-modal>
</template>

<script>
import {InputErrors, Processing, PublicLink, DialogModal} from "@/components/common";
import {aggregateValidationErrors} from "@/utils";
import {ValidationError} from "@/utils/api-client";
import {createInvestmentManager} from "@/utils/di";
import {DashboardEventType} from "../../../enums/DashboardEventType";
import {getCertificatesList, signData, isValid} from "@/utils/digital-sign/DigitalSign";
import {InvestmentConfirmationStepType} from "@/utils/api-client/enums/InvestmentConfirmationStepType";
import axios from "axios";
import {appConfig} from "@/configs";
import moment from "moment";

export default {
  name: "InvestmentConfirmation",
  components: {
    InputErrors,
    Processing,
    PublicLink,
    DialogModal
  },
  data() {
    return {
      confirmation: {},
      agreement: false,
      smsCode: null,
      errors: {},
      processing: false,
      certsLoaded: false,
      certificates: [],
      chosenCertificate: null,
      signPluginError: null,
      signatureCreateError: null,
      steps: [],
      signatureTurnedOff: appConfig.signatureTurnedOff
    };
  },
  async mounted() {
    this.steps = Object.values(InvestmentConfirmationStepType)
        .map(val => InvestmentConfirmationStepType[val])
        .filter(val => typeof val == "number");

    const manager = await createInvestmentManager();
    this.confirmation = await manager.getConfirmation(this.$route.params.confirmationUuid, this.$route.params.step);
    if (!this.confirmation.signRequired){
      const manager = await createInvestmentManager();
      await manager.confirmStep(this.$route.params.confirmationUuid.toString(), this.confirmation.step);
      this.confirmation.confirmed = true;
    }
    if (this.confirmation.isWithoutCollateral) this.steps = this.steps.slice(0,2);
  },
  methods: {
    validateForm() {
      const errors = {};
      if (!this.agreement) {
        errors.agreement = ['Пожалуйста, отметьте это поле'];
      }
      this.errors = errors;
      return Object.keys(errors).length === 0;
    },
    async getCert() {
      this.$refs.dialog.open();
      try {
        await isValid();
      } catch (error) {
        this.signPluginError = error.message;
      }
      try {
        this.certificates = await getCertificatesList();
      } catch (error) {
        this.signPluginError = error.message;
      } finally {
        this.certsLoaded = true;
      }
    },
    setCert(cert) {
      if (moment(cert.validTo).isBefore(moment())) {
        this.signatureCreateError = 'Срок действия сертификата закончился ' + moment(cert.validTo).format('YYYY-MM-DD');
        throw new Error('Срок действия сертификата закончился');
      }
      this.chosenCertificate = cert;
    },
    async signDocument() {
      this.processing = true;
      try {
        const response = await axios.get(this.confirmation.documentUrl,
            {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
              }
            });
        const signature = await signData(this.chosenCertificate, response.data);

        const manager = await createInvestmentManager();
        await manager.confirmStep(this.$route.params.confirmationUuid.toString(), this.confirmation.step, signature);
        this.confirmation.confirmed = true;
      } catch (err) {
        this.signatureCreateError = err.message;
      } finally {
        this.processing = false;
      }
    },
    async confirm(e) {
      e.preventDefault();
      if (this.steps[this.steps.length - 1].toString() === this.$route.params.step) {
        if (this.validateForm()) {
          this.processing = true;
          try {
            const manager = await createInvestmentManager();
            await manager.create(this.$route.params.confirmationUuid.toString());
            this.$emit("dashboardEvent", DashboardEventType.Invested);
            await this.$router.push({name: "myInvestments"});
          } catch (err) {
            if (err instanceof ValidationError) {
              this.$notifications.addAlert(err.message)
              this.errors = aggregateValidationErrors(err);
            } else {
              throw err;
            }
          } finally {
            this.processing = false;
          }
        }
      } else {
        await this.$router.push({
          name: 'investmentConfirmation',
          params: {
            uuid: this.confirmation.project.uuid,
            confirmationUuid: this.$route.params.confirmationUuid.toString(),
            step: this.nextStep(this.$route.params.step)
          }
        });
      }
    },
    nextStep(step) {
      switch (step) {
        case '1':
          return InvestmentConfirmationStepType.ACCEPT_OFFER;
        case '2':
          return InvestmentConfirmationStepType.FEE_AGREEMENT;
        case '3':
          return InvestmentConfirmationStepType.ACCEPT_FEE_AGREEMENT;
        default:
          return InvestmentConfirmationStepType.OFFER;
      }
    }
  }
}
</script>

<style lang="scss">
.investment-confirmation {
  iframe {
    width: 100%;
    height: 40rem;
  }

  .badge.badge-secondary {
    font-size: 1rem;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.75rem;
  }
}

.certificates-list {
  height: 400px;
  overflow-y: scroll;
}


.cert-card {
  width: 400px;

  &:hover {
    outline: 2px solid #21e58a;
  }

  &__chosen {
    background-color: lightyellow;
  }
}

.status {
  &__ok {
    background-color: #1cbd73;
  }

}
</style>
