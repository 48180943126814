import {getUserCertificates, createAttachedSignature, isValidSystemSetup} from "crypto-pro";

type CertInfo = {
    issuerName: string,
    name: string,
    subjectName: string,
    thumbprint: string,
    validFrom: string,
    validTo: string
}

export async function getCertificatesList(): Promise<Array<CertInfo>> {
    return await getUserCertificates();
}

export async function signData(cert: CertInfo, data: ArrayBuffer | string): Promise<string> {
    return await createAttachedSignature(cert.thumbprint, data);
}

export async function isValid(): Promise<any>{
    return await isValidSystemSetup();
}